import StarOutlineOutlined from '@mui/icons-material/StarOutlineOutlined'
import AdsClickOutlined from '@mui/icons-material/AdsClickOutlined'
import HomeMaxOutlined from '@mui/icons-material/HomeMaxOutlined'
import {lazy} from 'react'
import { Route } from 'react-router-dom'
import HistoryEduOutlined from '@mui/icons-material/HistoryEduOutlined'
import RssFeedOutlined from '@mui/icons-material/RssFeedOutlined'
import NotesOutlined from '@mui/icons-material/NotesOutlined'
import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined'
import PriceChangeOutlined from '@mui/icons-material/PriceChangeOutlined'
import ImageOutlined from '@mui/icons-material/ImageOutlined'


const Chat = lazy(() => import('pages/chat'))
const Ranking = lazy(() => import('pages/ranking'))
const NaverTraffic = lazy(() => import('pages/traffic'))
const User = lazy(() => import('pages/users'))
const Deposit = lazy(() => import('pages/deposit'))
const Notice = lazy(() => import('pages/notice'))
const QnA = lazy(() => import('pages/qna'))
const CoopangPrice = lazy(() => import('pages/coopang-price'))
const CoopangPriceChange = lazy(() => import('pages/coopang-price-change'))
const BlogAccountManager = lazy(() => import('pages/blogAcctManager'))
const PostsManager = lazy(() => import('pages/postsManager'))
const PostView = lazy(() => import('pages/postsManager/PostView'))
const AddImage = lazy(() => import('pages/post-image'))
const GenerateImage = lazy(() => import('pages/generate-image'))
const Preset = lazy(() => import("pages/preset"))
const WebsiteSeoBacklink = lazy(() => import("pages/seoandbacklink"))
const WebsiteSocialSignal = lazy(() => import("pages/websitesocialsignal"))
const SmmkingsOrderList = lazy(() => import("pages/smmkingsorderlist"))

const routes = [
  {
    subheader: "",
    items: [
      {
        label: "Home",
        href: "/home",
        icon: <HomeMaxOutlined />
      }
    ]
  },
  {
    subheader: "User",
    items: [
      {
        label: "User",
        href: "/user",
        icon: <NotesOutlined />,
        component: User
      },
      {
        label: "Deposit Points",
        href: "/deposit-points",
        icon: <AttachMoneyOutlined />,
        component: Deposit
      },
      {
        label: "Chat",
        href: "/chat",
        icon: <HistoryEduOutlined />,
        component: Chat
      },
      {
        label: "Notice",
        href: "/notice",
        icon: <RssFeedOutlined />,
        component: Notice
      },
      {
        label: "Q&A",
        href: "/qna",
        icon: <HistoryEduOutlined />,
        component: QnA
      },
      {
        label: "Preset",
        href: "/preset",
        icon: <NotesOutlined />,
        component: Preset
      }
    ]
  },
  {
    subheader: "Auto Write",
    items: [
      {
        label: "AutoWrite Account Manager",
        href: "/account-manger-auto-write",
        icon: <RssFeedOutlined />,
        component: BlogAccountManager
      },
      {
        label: "Auto Write Post",
        href: "/auto-write-post",
        icon: <HistoryEduOutlined />,
        component: PostsManager
      },
      {
        label: "Add Images",
        href: "/auto-write-add-images",
        icon: <ImageOutlined />,
        component: AddImage
      },
      {
        label: "Generate Images",
        href: "/auto-generate-images",
        icon: <ImageOutlined />,
        component: GenerateImage
      },
      {
        label: "Add Post",
        href: "/auto-write-post/add-post",
        icon: <HistoryEduOutlined />,
        component: PostView,
        hidden: true
      }
    ]
  },
  {
    subheader: "Ranking Check",
    items: [
      {
        label: "NSite Ranking",
        href: "/ranking",
        component: Ranking,
        icon: <StarOutlineOutlined />
      }
    ]
  },
  {
    subheader: "Traffic Services",
    items: [
      {
        label: "NSite Traffic",
        href: "/nsite-traffic",
        icon: <AdsClickOutlined />,
        component: NaverTraffic
      }
    ]
  },
  {
    subheader: "Coopang Services",
    items: [
      {
        label: "Coopang Price",
        href: "/coopang-price",
        component: CoopangPrice,
        icon: <AttachMoneyOutlined />
      },
      {
        label: "Price Change Report",
        href: "/price-change-report",
        component: CoopangPriceChange,
        icon: <PriceChangeOutlined />
      }
    ]
  },
  {
    subheader: "Smmkings Services",
    items: [
      {
        label: "Smmkings Order list",
        href: "/smmkings-order-list",
        component: SmmkingsOrderList,
        icon: <AttachMoneyOutlined />
      },
      {
        label: "Website SEO & Backlinks",
        href: "/website-seo-backlink",
        component: WebsiteSeoBacklink,
        icon: <AttachMoneyOutlined />
      },
      {
        label: "Website Social Signal",
        href: "/website-social-signal",
        component: WebsiteSocialSignal,
        icon: <PriceChangeOutlined />
      }
    ]
  },
]

function getDomRoutes() {
  const domRoutes = [] 
  routes.forEach(group => {
    if(!group.items) {
      return
    }
    group.items.forEach(item => {
      if(!item.component) {
        return
      }
      domRoutes.push(<Route path={item.href} key={item.href} Component={item.component}/>)
    })
  })
  return domRoutes
}

export {getDomRoutes}
export default routes