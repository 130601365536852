import Menu from "@mui/icons-material/Menu"
import { AppBar, Box, Button, Typography, useMediaQuery } from "@mui/material"
import IconWithToolTip from "components/IconWithToolTip"
import React from "react"
import { useDispatch } from "react-redux"
import { Route, useNavigate } from "react-router-dom"
import { setMenuOpen } from "store/Session"
import { logout } from "store/user"
const Settings = React.lazy(() => import('pages/settings'))

const routes = [
  {
    label: "Settings",
    path: "/settings",
    component: Settings
  }
]

function headerRoutes() {
  let domRoutes = []
  routes.forEach(route => {
    if(route.component) {
      domRoutes.push(<Route path={route.path} key={route.path} Component={route.component}/>)
    }
  })
  return domRoutes
}

export  {headerRoutes}

export default function() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'))

  return <AppBar sx={{
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 16px',
    }} position="sticky">
    <Typography>
      {!desktop && <IconWithToolTip title={'Open Menu'} onClick={() => dispatch(setMenuOpen(true))} icon={<Menu />}/>}
    </Typography>
    <Box>
      {
        routes.map(route => <Button key={route.path} onClick={() => navigate(route.path)} sx={{mr: 2}}> {route.label} </Button>)
      }
      <Button onClick={() => dispatch(logout())}> Logout </Button>
    </Box>
  </AppBar>
}