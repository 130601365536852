import { Box, Typography } from "@mui/material"

function PageNotFound(props) {
  return <Box sx={{padding: 6, paddingTop: 12}}>
    <Typography sx={{fontSize: '8rem', fontWeight: 'bold'}} component="center"> 404 </Typography>
    <Typography component="center" sx={{p: 1}}> Uh Oh! The page you were looking for is not found. </Typography>
  </Box>
}


export default PageNotFound