import {Provider} from 'react-redux'
import store, {persist} from './store';
import { CssBaseline, ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import { BrowserRouter } from 'react-router-dom'
import Layout from './layout';
import { PersistGate } from 'redux-persist/integration/react'
import Loading from 'components/Loading';
import { SnackbarProvider } from 'notistack'
import darkTheme from './theme/darkTheme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


const theme = responsiveFontSizes(createTheme(darkTheme))

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persist} loading={<Loading />}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>  
                <Layout />
                <CssBaseline />
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
