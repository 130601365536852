import Lock from "@mui/icons-material/Lock"
import { LoadingButton } from "@mui/lab"
import { Box, Grid, TextField, Typography } from "@mui/material"
import { usePostRequest } from "components/useNetworkRequest"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setUser } from "store/user"

function LoginForm() {

  const {inFlight, sendRequest} = usePostRequest({endPoint: '/v1/auth/login'})
  const [data, setData] = useState({email:'', password: ''}) 
  const authenticated = useSelector(state => state.user.authenticated)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if(authenticated) {
      navigate('/')
    }
  }, [authenticated])

  const setField = e => {
    setData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value 
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await sendRequest(data)
    if(!response || !response.user) {
      return
    }
    dispatch(setUser(response))
    navigate('/')
  }


  return <Box onSubmit={handleSubmit} component="form" sx={{display: 'flex', flexDirection: 'column', minWidth: 300, maxWidth: 300, '&>*': {marginBottom: '16px !important'}}}>
    <Typography variant="h5" fontWeight={'bold'} sx={{marginBottom: 0}}> Hi, Welcome to Naver </Typography>
    <Typography variant="caption" sx={{marginBottom: 3}} color={'textSecondary'}> Login now to track you site ranking and improve it </Typography>
    <TextField onChange={setField} variant="filled" label={"Email"} required name="email" fullWidth/>
    <TextField onChange={setField} variant="filled" label={"Password"} required name="password" type='password' fullWidth/>
    <LoadingButton type="submit" loading={inFlight} fullWidth variant="contained" endIcon={<Lock />}> Login </LoadingButton>
  </Box>
}

function Login(props) {
  return <Grid container sx={{height: '100vh'}}>
    <Grid item sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} xs={12} md={4}>
      <LoginForm />    
    </Grid>
    <Grid item sx={{height: '100%', backgroundColor: 'secondary.main', display: {xs: 'none', md: 'block'}}} xs={12} md={8}></Grid>
  </Grid>
}

export default Login