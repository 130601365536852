import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { enqueueSnackbar } from 'notistack'

function handleError(err) {
  if(err.response) {
    enqueueSnackbar(err.response.data.message, {variant: 'error'})
  }else{
    enqueueSnackbar(err.message)
  }

}

export function useDeleteRequest(netWorkData) {

  const [inFlight, setInFlight] = useState(false)
  const [response, setResponse] = useState(null)
  const user = useSelector(state => state.user)

  const sendRequest = async (body) => {
    let config = {headers: {}}
    if(user.authenticated) {
      config.headers['Authorization'] = 'Bearer '+user.accessToken
    }
    setInFlight(true)
    try{
      let _response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}${netWorkData.endPoint}`, {...config, data: body})
      setResponse(_response.data)
      setInFlight(false)
      return _response.data
    }catch(err){
      setInFlight(false)
      console.error(err)
      handleError(err)
      return null
    }
  }

  return { inFlight, response, sendRequest }

}

export function usePostRequest(netWorkData) {

  const [inFlight, setInFlight] = useState(false)
  const [response, setResponse] = useState(null)
  const user = useSelector(state => state.user)

  const sendRequest = async (body) => {
    let config = {headers: {}}
    if(user.authenticated) {
      config.headers['Authorization'] = 'Bearer '+user.accessToken
    }
    setInFlight(true)
    try{
      let _response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${netWorkData.endPoint}`, body, config)
      setResponse(_response.data)
      setInFlight(false)
      return _response.data
    }catch(err){
      setInFlight(false)
      console.error(err)
      handleError(err)
      return null
    }
  }

  return { inFlight, response, sendRequest }

}

export function useGetRequest(netWorkData) {

  const [inFlight, setInFlight] = useState(false)
  const [response, setResponse] = useState(null)
  const user = useSelector(state => state.user)

  useEffect(() => {
    if(netWorkData.sendOnAttach) {
      sendRequest(netWorkData.params || {})
    }
  }, [])

  const sendRequest = async (params) => {
    let config = {
      params: {...netWorkData.params, ...params},
      headers: {}
    }
    if(user.authenticated) {
      config.headers['Authorization'] = 'Bearer '+user.accessToken
    }
    setInFlight(true)
    try{
      let _response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}${netWorkData.endPoint}`, config)
      setResponse(_response.data)
      setInFlight(false)
    }catch(err){
      setInFlight(false)
      console.error(err)
      handleError(err)
      setResponse(null)
    }
  }

  return { inFlight, response, sendRequest }

}

export function usePatchRequest(netWorkData) {

  const [inFlight, setInFlight] = useState(false)
  const [response, setResponse] = useState(null)
  const user = useSelector(state => state.user)

  const sendRequest = async (body) => {
    let config = {headers: {}}
    if(user.authenticated) {
      config.headers['Authorization'] = 'Bearer '+user.accessToken
    }
    setInFlight(true)
    try{
      let _response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}${netWorkData.endPoint}`, body, config)
      setResponse(_response.data)
      setInFlight(false)
      return _response.data
    }catch(err){
      setInFlight(false)
      console.error(err)
      handleError(err)
      return null
    }
  }

  return { inFlight, response, sendRequest }

}