import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {List, ListItemButton, ListItemText, ListSubheader, Collapse, ListItemIcon, ListItemSecondaryAction} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import PropTypes from 'prop-types'
import {styled} from '@mui/material/styles'

const StyledListItemText = styled(ListItemText)(({theme}) => ({
  paddingLeft: 16,
  color: theme.palette.text.secondary,
  fontSize: 12,
  // fontWeight: 500,
  letterSpacing: 1.2,
  lineHeight: 2
}))

const StyledIcon = styled(ListItemIcon)(({theme}) => ({
  color: theme.palette.text.secondary,
  minWidth: 20,
  '& svg': {
    fontSize: 20
  }
}))

const StyledListButton = styled(ListItemButton)(({theme, special}) => {

  const defaultStyle = {
    '&.Mui-selected': {
      background: "transparent",
      position: "relative",
      "&::after": {
        content: '" "',
        position: "absolute",
        width: 4,
        height: "80%",
        right: 0,
        top: '50%',
        background: theme.palette.secondary.dark,
        display: "block",
        borderBottomLeftRadius: 4,
        borderTopLeftRadius: 4,
        transform: 'translateY(-50%)'
      },
      '& svg, & div': {
        color: theme.palette.secondary.dark,
      }
    }
  }

  if(!special){
    return (defaultStyle)
  }

  return {
    ...defaultStyle,
    '&.MuiListItemButton-root': {
      position: 'relative',
      overflow: 'hidden',
      zIndex: 1,
      '&::before': {
        content: "' '",
        position: 'absolute',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, .5) 55%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%, rgba(255, 255, 255, .5) 75%)',
        height: '100%',
        width: 40,
        left: 0,
        transform: 'skew(-45deg)',
        animationName: 'slide',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear'
      }
    }
  }

})

const Accordion = props => {
  let [opened, setOpened] = React.useState(false)
  return <React.Fragment>
    <ListItemButton onClick={() => setOpened(!opened)}>
      <StyledIcon>{props.icon}</StyledIcon>
      <StyledListItemText disableTypography>{props.heading}</StyledListItemText>
      <ListItemSecondaryAction> {opened ? <ExpandLess sx={{color: 'text.secondary'}}/> : <ExpandMore sx={{color: 'text.secondary'}}/> }</ListItemSecondaryAction>
  </ListItemButton>
  <li style={{paddingLeft: 16}}>
    <Collapse in={opened} unmountOnExit>
        <List divider>
            {
              props.item.items.map((el, index) =>  <RenderItem  key={index} item={el}/>)
            }
        </List>
      </Collapse>
    </li>
  </React.Fragment>
}

const RenderItem = ({item}) => {
  if(item.items === undefined || item.items?.length === 0) {
    return <StyledListButton sx={{padding: 0, paddingLeft: 2}} key={item.href} to={item.href} component={Link} selected={window.location.pathname.indexOf(item.href) !== -1}>
      <StyledIcon>{item.icon}</StyledIcon>
      <StyledListItemText disableTypography> {item.label} </StyledListItemText>
      <ListItemSecondaryAction> {item.secondaryIcon} </ListItemSecondaryAction>
    </StyledListButton>
  }
  return <Accordion heading={item.label} icon={item.icon} item={item} />
  
}

const CustomList = props => <List sx={{paddingBottom: 0}} subheader={<ListSubheader sx={{backgroundColor: 'transparent', color: '#fff', fontWeight: 400, letterSpacing: 1.2, marginTop: 2}}>{props.heading}</ListSubheader>}>
  {
    props.items.map(item => !item.hidden && <RenderItem
      special={props.special} 
      item={item} 
      key={item.label}/>)
  }
</List>

function MakeSideNavigation({items}){

  useLocation()

  return items.map((item, index) => <CustomList 
      key={index} 
      heading={item.subheader} 
      items={item.items}/>
    )
}

MakeSideNavigation.propTypes = {
  items: PropTypes.array.isRequired
}

MakeSideNavigation.defaultProps = {
  items: []
}

export default MakeSideNavigation