import { useDispatch } from "react-redux"
import { logout } from "store/user"


function Logout(){

  const dispatch = useDispatch()

  dispatch(logout())

  return <div>Logging out</div>
}


export default Logout